// Pallete
import pageSelector from "./pageSelector";

function getBiz({ config }) {
  return config.biz;
}

function getAPIBase({ config }) {
  switch (config.biz.env) {
    case "production":
      return "https://meraki-api.svc.urbanpiper.com/p";
    case "staging":
      return "https://meraki-api.staging.svc-hz.urbanpiper.com/graphql/p";
    case "pos-int":
      return "https://meraki-api-posint.services-staging.urbanpiper.com/p";
    default:
      return "https://meraki-api.svc.urbanpiper.com/p";
  }
}

function getHotjar({ config }) {
  return getAccessKeys({ config }).analytics.hotjar;
}

function getGraphqlBase({ config }) {
  switch (config?.biz?.env) {
    case "production":
      return "https://meraki-api.services.urbanpiper.com/graphql";
    case "staging":
      return "https://meraki-api.staging.svc-hz.urbanpiper.com/graphql/graphql";
    case "pos-int":
      return "https://meraki-api-posint.services-staging.urbanpiper.com/graphql";
    default:
      return "https://meraki-api.services.urbanpiper.com/graphql/";
  }
}

function getGraphqlWS({ config }) {
  switch (config?.biz?.env) {
    case "production":
      return "wss://meraki-api.services.urbanpiper.com/graphql";
    case "staging":
      return "wss://meraki-api.staging.svc-hz.urbanpiper.com/graphql/graphql";
    case "pos-int":
      return "wss://meraki-api-posint.services-staging.urbanpiper.com/graphql";
    default:
      return "wss://meraki-api.services.urbanpiper.com/graphql/";
  }
}

function getMerakiEditorBase({ config }) {
  switch (config?.biz?.env) {
    case "production":
      return "https://atlas.urbanpiper.com";
    case "staging":
      return "https://test-atlas.urbanpiper.com";
    case "pos-int":
      return "https://test-atlas.urbanpiper.com";
    default:
      return "https://atlas.urbanpiper.com";
  }
}

function getAuthToken({ config }) {
  const biz = getBiz({ config });
  return biz?.username;
}

function getThemeColors({ config }) {
  return config?.theme?.customizations?.colors;
}

function getFlags({ config }) {
  return config.customizations;
}

function getAccessKeys({ config }) {
  return config.customizations.accessKeys;
}

function getCustomPages({ config }) {
  return config.customPages;
}

function getGlobalComponent({ config, name }) {
  return config.theme.components?.find((component) => component?._id === name);
}

function getDeliveryPickupConfig({ config }) {
  return getFlags({ config }).deliveryPickup;
}

function getWallet({ config }) {
  return getFlags({ config }).wallet;
}

function getPrimaryColor({ config }) {
  let commonColors = getThemeColors({ config });
  return commonColors?.primaryColor;
}

function getSecondaryColor({ config }) {
  let commonColors = getThemeColors({ config });
  return commonColors?.secondaryColor;
}

function getTertiaryColor({ config }) {
  let commonColors = getThemeColors({ config });
  return commonColors?.tertiaryColor;
}

function getSuccessColor({ config }) {
  let commonColors = getThemeColors({ config });
  return commonColors?.successColor;
}

function getFailureColor({ config }) {
  let commonColors = getThemeColors({ config });
  return commonColors?.failureColor;
}

// Text
function getPrimaryTextColor({ config }) {
  let commonColors = getThemeColors({ config });
  return commonColors?.primaryTextColor;
}

function getSecondaryTextColor({ config }) {
  let commonColors = getThemeColors({ config });
  return commonColors?.secondaryTextColor;
}

//font
function getFontFamily({ config }) {
  const fontFamily = config?.theme?.customizations.font;

  if (!fontFamily || typeof fontFamily !== "string") {
    return "Open Sans";
  }

  return fontFamily;
}

//get enabled fulfilmentTypes
function getFulfilmentTypes({ config }) {
  const types = [];
  if (getFlags({ config }).deliveryPickup.enableDelivery) {
    types.push("delivery");
  }

  if (getFlags({ config }).deliveryPickup.enablePickup) {
    types.push("pickup");
  }

  return types;
}

function getUpfrontFulfillmentTimePromptFlag({ config }) {
  return getFlags({ config }).deliveryPickup.upfrontFulfillmentTimePrompt;
}

//get default fulfilmentType
function getDefaultFulfilmentMode({ config }) {
  let defaultMode = getFlags({ config }).deliveryPickup.defaultMode;
  if (!defaultMode) {
    const types = getFulfilmentTypes({ config });
    defaultMode = types.length ? types[0] : "delivery";
  }
  return defaultMode;
}

function getReferral({ config }) {
  return getAccessKeys({ config }).referral;
}

function getHideDeliverNow({ config }) {
  return getDeliveryPickupConfig({ config }).hideDeliverPickupNow;
}

function getEnableTimeSlots({ config }) {
  return getDeliveryPickupConfig({ config }).enableTimeSlots;
}

function getMaxPreOrderDate({ config }) {
  return getDeliveryPickupConfig({ config }).maxPreOrderDate;
}

function getHideDeliverNowAfterOffset({ config }) {
  return getDeliveryPickupConfig({ config }).hideDeliverPickupNowAfterOffset;
}

function getBizName({ config }) {
  return getBiz({ config }).name;
}

function getBizLogoURL({ config }) {
  return getBiz({ config }).logoUrl;
}
function getBizSquareLogoURLMobile({ config }) {
  return getBiz({ config }).logoUrlSquare;
}

function getMinReloadButtonValue({ config }) {
  return getWallet({ config }).minReloadBtnVal;
}
function getDisableReload({ config }) {
  return getWallet({ config }).disableReload;
}

function getSortByPriceLabel() {
  return "Price (Low to High)";
}

// custom pages
function getTermsPageContent({ config }) {
  const page = getCustomPages({ config })?.find(
    (page) => page?._id === "terms",
  );
  return page.content;
}

function getPrivacyPageContent({ config }) {
  const page = getCustomPages({ config })?.find(
    (page) => page?._id === "privacy",
  );
  return page.content;
}

// header background
function getHeaderColors({ config }) {
  const headerComponent = getGlobalComponent({ config, name: "header" });

  return {
    backgroundColor: headerComponent?.customizations?.backgroundColor,
    colors: config.constants.colors.header,
  };
}

function getFreshChat({ config }) {
  return getAccessKeys({ config }).chat.freshchat;
}

function getMixPanel({ config }) {
  return getAccessKeys({ config }).analytics.mixpanel;
}

function getGTM({ config }) {
  return getAccessKeys({ config }).analytics.gtm;
}
function getGA({ config }) {
  return getAccessKeys({ config }).analytics?.ga;
}

function getGoogleMapsKey({ config }) {
  return getAccessKeys({ config }).misc.googleMapKey;
}

// social login
function getFacebookLogin({ config }) {
  return getAccessKeys({ config }).socialLogin.facebook;
}

function getGoogleLogin({ config }) {
  return getAccessKeys({ config }).socialLogin.google;
}

// wallet
function getWalletConfig({ config }) {
  return getFlags({ config }).wallet;
}

function enableReorder({ config }) {
  return pageSelector.getOrderHistoryPage({ config }).customizations.reorder;
}

function getFavicon({ config }) {
  return getBiz({ config }).favicon;
}

function getSocialMediaLinks({ config }) {
  const component = getGlobalComponent({ config, name: "footer" });
  return component?.customizations?.socialMediaLinks || {};
}

function getExternalPageLinks({ config }) {
  const component = getGlobalComponent({ config, name: "footer" });
  return component?.customizations?.externalPageLinks || [];
}

function getFooterBgColor({ config }) {
  const component = getGlobalComponent({ config, name: "footer" });
  return component?.customizations?.bgColorMode || "default";
}

function getTopHeaderLeftLink({ config }) {
  const component = getGlobalComponent({ config, name: "topbar" });

  return component?.customizations?.leftLink || {};
}

function getTopHeaderRightLink({ config }) {
  const component = getGlobalComponent({ config, name: "topbar" });

  return component?.customizations?.rightLink || {};
}

function getHideOrderingTime({ config }) {
  return getDeliveryPickupConfig({ config }).hideOrderingTime;
}

function getCopyrightText({ config }) {
  const component = getGlobalComponent({ config, name: "footer" });

  return component?.customizations?.copyrightText;
}

function getContactUs({ config }) {
  const component = getGlobalComponent({ config, name: "footer" });

  return component?.customizations?.contactUs;
}

function getPaymentAlert({ config }) {
  return pageSelector.getCheckoutPage({ config }).customizations.message;
}

function getGuestCheckout({ config }) {
  return getFlags({ config }).guestCheckout || {};
}

function getIsGuestCheckoutEnabled({ config }) {
  const checkout = getGuestCheckout({ config });
  if (typeof checkout.enable === "boolean") {
    return checkout.enable;
  }

  // enabled is not defined. we default to true
  return true;
}

function getIsCODEnabledGuestCheckout({ config }) {
  return getGuestCheckout({ config }).cashOnDelivery;
}

function getIsEmailRequiredGuestCheckout({ config }) {
  const guestCheckout = config.customizations.guestCheckout;
  if (typeof guestCheckout.emailRequired === "boolean") {
    return guestCheckout.emailRequired;
  }

  // emailRequired is not defined. we default to false
  return false;
}

function getDefaultLanguage({ config }) {
  return config?.biz?.defaultLanguage;
}

function getNpsFeedback({ config }) {
  return getFlags({ config }).npsFeedback || {};
}

function getImagesSizeFromConfig({ config }) {
  return config.customizations.imageSize;
}

function getOrderSuccessDeliveryMessage({ config }) {
  return (
    getFlags({ config }).deliveryPickup?.order_detail_delivery_dt_msg || ""
  );
}

function getUrbanPiperBrandingFlag({ config }) {
  if (getFlags({ config })?.showUrbanpiperBranding === false) {
    return false;
  }
  return true;
}

function getReferralBool({ config }) {
  return getFlags({ config })?.accessKeys?.referral?.enable;
}

function getMode({ config }) {
  return getBiz({ config })?.mode;
}

function getSubPath({ config }) {
  return getBiz({ config })?.urlSubPath || "";
}

function getSubPathWithSlash({ config }) {
  return getSubPath({ config })?.length > 0 ? `/${getSubPath({ config })}` : "";
}

function getTopbar({ config }) {
  return getGlobalComponent({ config, name: "topbar" });
}

const configSelector = {
  getPrimaryColor,
  getSecondaryColor,
  getTertiaryColor,
  getSuccessColor,
  getFailureColor,
  getPrimaryTextColor,
  getSecondaryTextColor,
  getFontFamily,
  getFulfilmentTypes,
  getDefaultFulfilmentMode,
  getReferral,
  getHideDeliverNow,
  getEnableTimeSlots,
  getOrderSuccessDeliveryMessage,
  getMaxPreOrderDate,
  getHideDeliverNowAfterOffset,
  getBizName,
  getBizLogoURL,
  getBizSquareLogoURLMobile,
  getMinReloadButtonValue,
  getDisableReload,
  getSortByPriceLabel,
  getMode,

  getTermsPageContent,
  getPrivacyPageContent,

  getHeaderColors,
  getThemeColors,
  getFreshChat,
  getMixPanel,
  getGTM,
  getGA,
  getGoogleMapsKey,

  getFacebookLogin,
  getGoogleLogin,

  getWalletConfig,
  enableReorder,
  getFavicon,
  getCustomPages,
  getSocialMediaLinks,
  getExternalPageLinks,

  getTopHeaderLeftLink,
  getTopHeaderRightLink,
  getHideOrderingTime,
  getCopyrightText,
  getPaymentAlert,
  getGuestCheckout,
  getIsCODEnabledGuestCheckout,
  getIsGuestCheckoutEnabled,
  getIsEmailRequiredGuestCheckout,
  getDefaultLanguage,
  getNpsFeedback,
  getImagesSizeFromConfig,
  getContactUs,
  getUrbanPiperBrandingFlag,
  getReferralBool,
  getAPIBase,
  getUpfrontFulfillmentTimePromptFlag,
  getGraphqlBase,
  getGraphqlWS,
  getAuthToken,
  getSubPath,
  getHotjar,
  getFlags,
  getTopbar,
  getFooterBgColor,
  getMerakiEditorBase,
  getBiz,
  getSubPathWithSlash,
};

export default configSelector;
